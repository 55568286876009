@use '@angular/material' as mat;

// Custom.scss
// Option B: Include parts of Bootstrap
// Required
@import "../node_modules/bootstrap/scss/functions";

@import "securamax-theme.scss";

$h1-font-size: 24px;
$h2-font-size: 1.25rem;
$h3-font-size: 1.0rem;
$headings-font-weight: 400;
$headings-font-family: $my-font-family;
$font-family-sans-serif: $my-font-family;
$font-family-serif:      $my-font-family;
$font-family-monospace:  $my-font-family;
$font-family-base: $my-font-family;

@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/reboot";

@include mat.core();

@import "assets/fonts/fonts-poppins.min";
@font-face {
    font-family: 'humanist521';
    font-weight: 400;
    font-style: normal;
    src: url("assets/fonts/light.woff2") format('woff2');
}


// Include custom variable default overrides here
// Optional
// Document
// this is from reboot. we don't want everything as it messes other stuff up
// 1. Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.
// 2. Change the default font family in all browsers.
// 3. Correct the line height in all browsers.
// 4. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
// 5. Change the default tap highlight to be completely transparent in iOS.
*,
*::before,
*::after {
    box-sizing: border-box; // 1
}

@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/code";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/breadcrumb";



.scroll {
    overflow-y: auto;
}

h3 {
    font-weight: bold;
}

.mini.mini.mini {
    font-size: 9px;
    padding: 4px;
    border-radius: 6.7px;
}

.page-title-container{
    padding-top:15px;
    background-color: white;
}

.mat-list-item.active{
    background: rgba(0, 0, 0, 0.04);
}

body {
    background-color: #d6d6d7;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    background-color: unset !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
    color: unset !important;
}

.mat-mdc-menu-item .mat-icon-no-color, .mat-mdc-menu-item .mat-mdc-menu-submenu-icon {
    color: #fff !important;
}

html {
    --mat-toolbar-standard-height: 72px;
    --mat-toolbar-mobile-height: 56px;
    --mat-sidenav-content-background-color: #f3f3f3;
    --mat-icon-color: #fff;
    --mat-card-title-text-font: 'Roboto', sans-serif;
    --mat-toolbar-title-text-font: 'Roboto-Black', sans-serif;
    --mdc-typography-button-text-transform: uppercase;
    --mdc-typography-button-font-size: 16px;
    --mdc-typography-button-letter-spacing: 1.6px;


    --navbar-body-card-top-margin: 16px;
    --body-card-padding: 42px;
    --body-card-header-height: 24px;
    --body-card-header-margin-bottom: 12px;
    --total-height-remaining-card: calc(var(--navbar-body-card-top-margin) + var(--mat-toolbar-standard-height) + 16px);
    --total-height-remaining: calc(var(--navbar-body-card-top-margin) + var(--body-card-padding) + var(--body-card-header-height) + var(--body-card-header-margin-bottom) + var(--mat-toolbar-standard-height) + 16px);
    --fixed-height-page: calc(100dvh - var(--total-height-remaining));
    --fixed-height-page-card: calc(100dvh - var(--total-height-remaining-card));
}

label {
    display: inline;
    margin-bottom: 0;
}








.mat-mdc-button.mat-mdc-button-base.mat-mdc-button.mat-mdc-button-base {
    height: unset;
}
.mat-mdc-button.mat-mdc-button:not(:disabled) {
    color: #fff;
}
.mat-mdc-button.mat-mdc-button:disabled:not(.smx-primary-button) {
    color: rgb(83 83 83);
}

.mdc-button.mdc-button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    min-width: 64px;
    border: none;
    outline: none;
    line-height: inherit;
    user-select: none;
    -webkit-appearance: none;
    overflow: visible;
    vertical-align: middle;
}
.mat-mdc-mini-fab.mat-mdc-mini-fab:not(.mdc-fab--extended) {
    padding: unset;
}



.smx-primary-button.smx-primary-button {
    background-color: rgb(123, 201, 89);
    border: none;
    border-radius: 14.79999999999993px;
    padding: 10px 18.00000000000002px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.1rem;

    &:hover {
        color: #fff;
        text-decoration: none;
    }

    &:disabled {
        background-color: rgb(187, 229, 166);
    }
}

.smx-secondary-button.smx-secondary-button {
    background-color: rgb(39, 119, 211);
    border: none;
    border-radius: 14.79999999999993px;
    padding: 10px 18.00000000000002px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.1rem;

    &:hover {
        color: #fff;
        text-decoration: none;
    }

    &:disabled {
        background-color: rgb(102 150 205);
    }
}



.smx-critical-button.smx-critical-button {
    background-color: rgb(238, 20, 20);
    border: none;
    border-radius: 14.79999999999993px;
    padding: 10px 18.00000000000002px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.1rem;

    &:hover {
        color: #fff;
        text-decoration: none;
    }

    &:disabled {
        background-color: rgb(227, 142, 142);
    }
}


button.disabled {
    background-color: rgb(182, 182, 182);
}



.mat-mdc-menu-panel {
    border-radius: unset !important;;
    background-color: rgb(37 119 218) !important;
}

.mat-mdc-menu-item, .mat-mdc-menu-item:visited, .mat-mdc-menu-item:link {
    color: white !important;
}

.mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
    font-family: $my-font-family !important;
    text-transform: uppercase !important;
}

.mdc-form-field .mdc-label {
    margin-bottom: unset;
}

.fake-label {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.badge {
    font-size: unset !important;
}


:root {
    --dropscroll-area-radius: 13px;

}

.dropscroll-area-0 {
    height: calc(var(--fixed-height-page) - 26px * 2);
    background-color: hsl(0, 0%, 93%);
    border-radius: var(--dropscroll-area-radius);
    box-shadow: inset 0px 6px 20px -3px rgba(0,0,0,0.3);
    position: relative;
}

.dropscroll-area-0::before,
.dropscroll-area-0::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: 20px;
    z-index: 2;
}

.dropscroll-area-0::before {
    top: 0;
    background: linear-gradient(to bottom, rgb(100 100 100 / 22%), rgba(255,255,255,0));
    border-top-left-radius: var(--dropscroll-area-radius);
    border-top-right-radius: var(--dropscroll-area-radius);
}

.dropscroll-area-0::after {
    height: 10px;
    bottom: 0;
    background: linear-gradient(to top, rgb(100 100 100 / 14%), rgba(255,255,255,0));
    border-bottom-left-radius: var(--dropscroll-area-radius);
    border-bottom-right-radius: var(--dropscroll-area-radius);
}

.dropscroll-area-1 {
    height: 100%;
    overflow-y: auto;
}



.card-body {
    box-shadow: #38383852 0px 2px 3px 1px;
}



.label-and-control-container {
    display: grid;
    grid-template-rows: auto 1fr;
    align-items: start;
}


.vis-item.vis-item {
    font-family: $my-font-family;
    position: absolute;
    color: #fff;
    border-color: #97b0f8;
    border-width: 0px;
    background-color: #959aab;
    display: inline-block;
    z-index: 1;
}


.vis-item.vis-selected.vis-selected {
    border-color: #7BC959 ;
    background-color: #7BC959 ;
    z-index: 2;
}

