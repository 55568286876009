@use '@angular/material' as mat;

@font-face {
    font-family: 'Roboto-Black';
    font-style: normal;
    font-weight: 900;
    src: url('./assets/fonts/Roboto/Roboto-Black.ttf') format('ttf');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}


// $my-primary: mat.define-palette(mat.$indigo-palette, 500);
// $my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The "warn" palette is optional and defaults to red if not specified.
$my-warn: mat.define-palette(mat.$red-palette);


$md-smxprimary: (50: #e7e7e7, 100: #c2c2c2, 200: #999999, 300: #707070, 400: #525252, 500: #333333, 600: #2e2e2e, 700: #272727, 800: #202020, 900: #141414, A100: #ef6e6e, A200: #ea4040, A400: #f60000, A700: #dd0000, contrast: (50: #000000, 100: #000000, 200: #000000, 300: #ffffff, 400: #ffffff, 500: #ffffff, 600: #ffffff, 700: #ffffff, 800: #ffffff, 900: #ffffff, A100: #000000, A200: #ffffff, A400: #ffffff, A700: #ffffff,));
$md-mcgpalette2: (50: #e3effa, 100: #bad6f2, 200: #8cbbe9, 300: #5e9fe0, 400: #3c8bd9, 500: #1976d2, 600: #166ecd, 700: #1263c7, 800: #0e59c1, 900: #0846b6, A100: #e0eaff, A200: #adc6ff, A400: #7aa3ff, A700: #6191ff, contrast: (50: #000000, 100: #000000, 200: #000000, 300: #000000, 400: #ffffff, 500: #ffffff, 600: #ffffff, 700: #ffffff, 800: #ffffff, 900: #ffffff, A100: #000000, A200: #000000, A400: #000000, A700: #000000,));

$my-primary: mat.define-palette($md-smxprimary);
$my-accent: mat.define-palette($md-mcgpalette2);

$my-font-family: humanist521, -apple-system, BlinkMacSystemFont, segoe ui, Roboto, helvetica neue, Arial, noto sans, sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol, noto color emoji;

$my-custom-typography-config: mat.define-typography-config(
    $button: mat.define-typography-level(14px, $font-weight: 700, $font-family: $my-font-family)
);

$my-theme: mat.define-light-theme((
    color: (
        primary: $my-primary,
        accent: $my-accent,
        warn: $my-warn,
    ),
    typography: $my-custom-typography-config,
    density: 0,
));


// Emit theme-dependent styles for common features used across multiple components.
@include mat.core-theme($my-theme);

// Emit styles for MatButton based on `$my-theme`. Because the configuration
// passed to `define-light-theme` omits typography, `button-theme` will not
// emit any typography styles.
@include mat.all-component-themes($my-theme);

.filter-settings-container mat-form-field {
    @include mat.form-field-density(-5);
}

.custom-nav-list {
    padding: 10px;

    mat-divider {
        display: none;
    }

    .mdc-list-item {
        width: unset;
    }

    .mdc-list-item:focus .mdc-list-item__primary-text,
    .mdc-list-item:hover .mdc-list-item__primary-text,
    .mdc-list-item__primary-text {
        color: white;
    }

    .active,
    .active:focus,
    .active:hover  {
        background-color: #7bc959;
    }

    a,
    a:focus,
    a:hover {
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        font-family: $my-font-family;
        background-color: #2572cb;
        color: white;
        border-radius: 14px;
        margin: 0 0.5rem 0.5rem 0.5rem;
    }
}
